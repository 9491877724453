import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'
import localDB from '../common/localDB'
import requester from '../common/requester'
import { SelectionStore } from './SelectionStore'

export class AuditStore extends SelectionStore {
  @observable agents = observable.array()
  @observable contractors = observable.array()
  @observable routes = observable.array()
  @observable contractor_search = ''
  @observable route_search = ''

  constructor() {
    super()
    makeObservable(this)
  }

  @computed
  get contractors_filtered() {
    return this.contractor_search
      ? this.contractors.filter(this.filter_contractors)
      : this.contractors.slice()
  }

  filter_contractors = contractor => {
    return contractor.name
      .toLowerCase()
      .includes(this.contractor_search.toLowerCase())
  }

  @computed
  get routes_filtered() {
    return this.route_search
      ? this.routes.filter(this.filter_routes)
      : this.routes.slice()
  }

  filter_routes = route => {
    return route.name.toLowerCase().includes(this.route_search.toLowerCase())
  }

  isReadOnly() {
    return !this.item.user_id || !this.item.contractor_id || super.isReadOnly()
  }

  setSingle(item) {
    if (item.data.audit_status === 'done') {
      this.setAccess(['view'])
      this.setStructure(this.structure.map(this.mapStructureReadOnly))
    }
    super.setSingle(item)
    if (!this.item.user_id) {
      this.loadAgents()
    }
  }

  loadAgents() {
    return this.fetchAgents()
  }

  async fetchAgents() {
    if (localDB.hasConnection) {
      const data = await requester.get('/agent/list')
      this.setAgents(data.data.list)
    } else {
      let data = await localDB.get('/users', {}, 'fio', true)
      data && this.setAgents(data.list)
    }
  }

  @action
  setAgents(list) {
    this.agents.replace(list)
  }

  loadRoutes() {
    return this.item.user_id && this.item.user && this.fetchRoutes()
  }

  async fetchRoutes() {
    let data = await localDB.get(
      '/routes',
      { 'filter[branch_id]': this.item.user.branch_id },
      'name',
      true,
    )
    data && this.setRoutes(data.list)
  }

  @action
  setRoutes(list) {
    this.routes.replace(list)
  }

  loadContractors() {
    return this.item.user_id && this.item.user && this.fetchContractors()
  }

  async fetchContractors() {
    let data = await localDB.get(
      '/contractors',
      {
        'filter[branch_id]': this.item.user.branch_id,
        'filter[route_id]': this.item.data.route_id,
      },
      'name',
      true,
    )
    data && this.setContractors(data.list)
  }

  @action
  setContractors(list) {
    this.contractors.replace(list)
  }

  clearItem() {
    super.clearItem()
    this.agents?.clear()
    this.routes?.clear()
    this.contractors?.clear()
  }

  @action
  selectUser(user) {
    this.item.user = user
    this.item.user_id = user ? user.id : null
    this.loadRoutes()
  }

  @action
  selectRoute(route) {
    if (route) {
      this.item.data.route = {
        id: route.id,
        name: route.name,
        data: route.data,
        branch_id: route.branch_id,
      }
      this.item.data.route_id = route.id
    }
    this.loadContractors()
  }

  @action
  selectContractor(contractor) {
    this.item.contractor = contractor
    this.item.contractor_id = contractor ? contractor.id : null
  }

  @action
  startAudit = () => {
    this.item.data.audit_datetime = moment().format('YYYY-MM-DD HH:mm:ss')
  }

  @action
  setStatusDone() {
    if (this.item.data) {
      this.item.data.audit_status = 'done'
      this.item.data.done_datetime = moment().format('YYYY-MM-DD HH:mm:ss')
    }
  }
}

const store = new AuditStore()
export default store

export const optionsBit = [
  { value: 0, text: '0' },
  { value: 1, text: '1' },
]
export const optionsTwo = [...optionsBit, { value: 2, text: '2' }]

export const auditFields = [
  { label: 'Знание ТП по имени', name: 'agent_name', options: optionsBit },
  {
    label: 'Работа с персоналом',
    name: 'work_with_staff',
    options: optionsBit,
  },
  { label: 'Дни посещения', name: 'visit_days', options: optionsBit },
  {
    label: 'Соответсвие консигнации с фактом',
    name: 'consistency_with_the_fact',
    options: optionsTwo,
  },
  { label: 'Эксклюзив', name: 'exclusive', options: optionsBit },
  { label: 'POSM', name: 'posm' },
  { label: 'Ценники', name: 'price_list', options: optionsBit },
  { label: 'Баннер', name: 'banner', options: optionsBit },
  { label: 'Наклейки', name: 'stickers', options: optionsBit },
  { label: 'Сертификаты', name: 'certificates', options: optionsBit },
  { label: 'Работа с POSM', name: 'working_with_posm', options: optionsBit },
  { label: 'Товарный запас', name: 'commodity_stock', options: optionsTwo },
  { label: 'Самоклейка на весы', name: 'self_adhesive', options: optionsBit },
  { label: 'Разделитель', name: 'delimiter', options: optionsBit },
  { label: 'Шелфтокер', name: 'shelf_talker', options: optionsBit },
  { label: 'Комментарий (50 символов)', name: 'comment', textarea: true },
]
